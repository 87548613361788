:root {
  --bg-dot: url(https://i.ibb.co/vP6NW21/ickle-wickle-dark.gif);
  --bg-img: url(https://img.nickpic.host/EqxBqQ.png);
  --grey-dark: #0a0a0a;
  --grey-light: #DDDDDD;
  --pink: #cd64ff;
  --green: #b1fca3;
  --purple: #786eff;
  --light-blue: #95cbf1;
  --blue: #55b1f1;
  --light-purple: #d2cdfa;
  --glow-green: 0px 0px 25px 5px #6fa864;
  --glow-white: 0px 0px 25px 5px #ddd;
  --glow-purple: 0px 0px 25px 5px #786eff;
  --glow-light-purple: 0px 0px 25px 5px #d2cdfa;
  --glow-light-blue: 0px 0px 25px 5px #55b1f1;
  --footer-gradient: linear-gradient(var(--grey-dark), var(--grey-dark) 10%, var(--purple) 70%);
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital@0;1&family=Orbitron:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&family=Inconsolata&display=swap');

.font-options {
  font-family: 'Syne Mono', sans-serif;
  font-family: 'Inconsolata', sans-serif;
}

body {
  font-family: 'Syne Mono', sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--grey-dark);
  color: var(--grey-light);
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Hellplague";
  src: local("/Users/larissaenright/dev/deathbed/src/fonts/hellplague.otf"),
   url(./fonts/hellplague.otf) format("OpenType");
}

a:link, a:visited {
  text-decoration: none;
  
}


a:hover, a:active {
  text-decoration: underline;
}

.overlay {
  z-index: 2;
}

.overlay::before {
content: " ";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  justify-content: center;
}

.content {
  padding: 0px 25px 0px 25px;
}


.nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.navigation {
  min-width: 16%;
  text-align: center;
  background: #fff;
  padding: 30px;
  margin-top: -40px;
  border-radius: 100px 100px 100px 100px;
  z-index:5;
}

.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link {
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 1em;
  padding: 5px 10px 5px 10px;
  margin: 0px 5px 0px 0px;
  }

.active-link {
    color: var(--purple);
  }

a.active-link {
    text-decoration: underline;
  }
  

.loading-container {
  width: 98%;
  height: 60px;
  margin: 50px 0px 40px 0px;
  padding: 10px 0px 30px 0px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  background-color: var(--grey-dark);
  font-size: 1em;
  color: var(--grey-light)
}

.footer-container {
  display: flex;
  justify-content: center;
  background-color: var(--grey-dark);
  background-image: var(--footer-gradient);
  padding: 20px 0px 40px 0px;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
}

@media screen and (max-width: 620px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
  }
}


.footer-col {
  padding: 0px 25px 0px 25px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 620px) {
  .footer-col {
    padding: 0px 0px 10px 0px;
    margin: 10px 0px 0px 0px;
  }
}

.footer-col-text {
  padding: 0px 25px 0px 25px;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column; 
}

@media screen and (max-width: 480px) {
  .footer-col-text {
  padding: 0px 0px 0px 0px;
  margin: 20px 10px 20px 10px;
  }
}


.center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.subtitle {
  font-size: 32px;
  padding-bottom: 10px;
}

.subtitle {
  font-size: 32px;
  padding-bottom: 10px;
}

.goodbye {
  font-size: 26px;
}

.page-title {
  font-size: 2em;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

@media screen and (max-width: 480px) {
  .page-title {
    margin-bottom: 10px;
  }
}



a.footer-link {
  text-decoration: underline;
  color: var(--grey-light)
}

a.footer-link:hover {
  color: var(--green)
}

a.body-link {
  text-decoration: underline;
  padding: 0px 2px 0px 2px;
  color: var(--grey-light)
}

a.body-link:hover {
  color: var(--green)
}




.body-container {
  padding-bottom: 50px;
  max-width: 1200px;
  overflow: visible;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .body-container {
    padding-bottom: 20px;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  padding-top: 50px;
  max-width: 1400px;
}

@media screen and (max-width: 480px) {
  .cards {
    padding-top: 0px;
  }
}


.card {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 10px 10px 20px 10px;
  margin: 20px 15px 20px 15px;
  width: 320px;
  background-image: var(--bg-dot);
  background-color: var(--grey-dark);
  background-blend-mode: overlay;
  transition: box-shadow 1s; 
  z-index: 1;
}

.card:hover {
  box-shadow:0px 0px 10px var(--grey-light),
  0px 0px  40px var(--grey-light),
  0px 0px  100px var(--grey-light),
  0px 0px  10px var(--grey-light);
}


.card-content {
  padding-top: 10px;
  margin: 0px -10px 0px -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-col {
  font-weight: 700;
  font-style: italic;
  font-size: 18px;
  padding-right: 10px;
}

.card-row{
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-right: 10px;  
}

.card-subtitle {
  font-weight: 700;
  font-style: italic;
  font-size: 18px;
}

.card-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  font-size: 1.2rem;
  border-radius: 80%;
  padding: 5px 10px 5px 10px;
  margin: 5px 0px 0px 10px;
  color: var(--grey-dark);
}

.tag-ETH {
  background-color: var(--light-purple);
}

.tag-FTM {
  background-color: var(--light-blue);
}

.small {
  font-size: 1rem;
  font-style: italic;
}

.unicode-divider {
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
}

.card-img {
  width:290px;
  height: 290px;
  margin-top: -297px;
  object-fit: cover;
  transition: filter 1s;
  filter: grayscale(100%);
}


.card-img:hover {
  filter: none;
}

.card-text {
  font-size: 16px;
}


.on-page-nav {
  text-align: center;
  padding: 40px 0px 20px 0px;
  
}


.scroll {
  position: fixed;
  bottom: 40px;
  left: 40px
}

@media screen and (max-width: 768px) {
  .scroll {
    position: fixed;
    bottom: 20px;
    left: 20px;
  }
}

.btn {
  font-size: 24px;
  cursor:pointer;
}

@media screen and (max-width: 768px) {
  .btn {
    font-size: 18px;
  }
}

.btn-round {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1;
  font-family: 'Syne Mono', sans-serif;
  transition: z-index 1s;
}

@media screen and (max-width: 620px) {
  .btn-round {
    width: 80px;
    height: 80px;
  }
}

.btn-oval {
  width: 180px;
  padding: 10px 20px 10px 20px;
  border-radius: 50%;
  z-index: 1;
  font-family: 'Syne Mono', sans-serif;
  transition: z-index 1s;

}

.btn-sqr {
  width: 80px;
  height: 245px;
  padding: 0px 35px 0px 15px;
  border-radius: 0%;
  z-index: 1;
  font-family: 'Syne Mono', sans-serif;
  position: fixed;
  top: 2.5rem;
  right: 0px;
}

@media screen and (max-width: 768px) {
  .btn-sqr {
    height: 200px;
    width: 25px;
    padding: 0px 30px 0px 5px;
    bottom: 0px;
    top: 30%
  }
}

.btn-green {
  background-color: transparent;
  border: 2px solid var(--green);
  color: var(--grey-light);
  transition: box-shadow 1s, background-color 1s;
}

.btn-green:hover {
  background-color: var(--grey-dark);
  box-shadow: var(--glow-green);
}

.btn-purple {
  background-color: transparent;
  background: var(--bg-dot);
  border-top: 2px solid var(--light-purple);
  border-bottom: 2px solid var(--light-purple);
  border-left: 2px solid var(--light-purple);
  border-right: 0px solid var(--light-purple);
  color: var(--grey-light);
  transition: box-shadow 1s, background-color 1s;
}

.btn-purple:hover {
  background-color: var(--grey-dark);
  box-shadow: var(--glow-purple);

}

.btn-blue {
  background-color: transparent;
  background: var(--bg-dot);
  border-top: 2px solid var(--light-blue);
  border-bottom: 2px solid var(--light-blue);
  border-left: 2px solid var(--light-blue);
  border-right: 0px solid var(--light-blue);
  color: var(--grey-light);
  transition: box-shadow 1s, background-color 1s;
}

.btn-blue:hover {
  background-color: var(--grey-dark);
  box-shadow: var(--glow-light-blue);
}

.upside {
  writing-mode: vertical-rl;
}

.card-link {
  font-style: normal;
  padding-top: 2px;
  color: var(--grey-light);
  font-size: 17px;
}

.card-link:hover {
  color: var(--purple);
}


.arch{
  border-radius: 500px 500px 0 0; 
}

.card-col-content {
  display: none;
}

.card-name {
  margin-top: 10px;
  fill: var(--grey-light);
  font-size: 8px;
}


.card-media {
  display: flex;
  justify-content: center;

}

.w-100 {
  width:100%;
}

.body-outline {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    border: 4px solid var(--grey-light);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media screen and (max-width: 375px) {
  .body-outline {
    overflow: auto;
    border: 0px solid var(--grey-light);
  }
}



.border {
  border: 2px solid var(--grey-light);
}


.border-bot {
  border-bottom: 2px solid var(--grey-light);
}

.header {
  margin: 10px 0px 0px 0px;
  font-size: 24vw;
  padding-bottom: 25px;
  color: var(--grey-light); 
  
}

@media screen and (min-width: 1200px) {
  .header {
     font-size: 280px;
     padding-bottom: 0px;

  }
}

.header:link, .header:visited, .header:hover {
text-decoration: none;
color: var(--grey-light); 
}

.font-sans {
  font-family: 'Syne Mono', sans-serif;
}

.font-hell {
  font-family: "Hellplague";
}

.glitch {
  text-shadow: 3px 0 1px rgb(0 30 255 / 50%), 
               -3px 0 1px rgb(255 0 80 / 30%), 
               0 0 3px;
}

.box-glitch {
  box-shadow: 3px 0 1px rgb(0 30 255 / 50%), 
               -3px 0 1px rgb(255 0 80 / 30%), 
               0 0 3px;
}

.glow {
  text-shadow:0px 0px 10px var(--grey-light),
  0px 0px  40px var(--grey-light),
  0px 0px  120px var(--grey-light),
  0px 0px  200px var(--grey-light);
}

.glow-gray {
  box-shadow: var(--glow-white);
}

.glow-green {
  box-shadow: var(--glow-green);
}

.glow-purple {
  box-shadow: var(--glow-purple);
}


.box-glow {
  box-shadow:0px 0px 10px var(--grey-light),
  0px 0px  40px var(--grey-light),
  0px 0px  100px var(--grey-light),
  0px 0px  10px var(--grey-light);
}

.purple {
  color: var(--purple);
}

.pink {
  color: var(--pink);
}

.green {
  color: var(--green);
}

.blue {
  color: var(--light-blue);
}

.light-purple {
  color: var(--light-purple);
}

.light-grey {
  color: var(--grey-light)
}


.load-more {
  margin-top: -71px;
  padding: 1rem;
  border: none;
  writing-mode:vertical-rl;
}

.load-more-txt {
  display: inline-block;
  transform: rotate(90deg);
}

#error-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background: var(--grey-dark);
  background-image: url(../public/angel-liquid-dither-lg.png);
  background-repeat: repeat;
  background-size: contain;
  background-position: bottom left;
}

.error-msg {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--grey-dark);
  padding: 10px;
  display: block;
}

.data {
  padding: 20px 0px 50px 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

}
.graph {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 860px;
}

@media screen and (max-width: 1150px) {
  .graph {
    max-width: 100%;
  }
}

@media screen and (max-width: 620px) {
  .graph {
    max-width: 400px;
  }
}

.stats {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    justify-content: center;
}

@media screen and (max-width: 1150px) {
  .stats-container {
    width: 100%;
    align-items: center;
  }
}


@media screen and (max-width: 620px) {
  .stats-container {
    width: 100%;
    align-items: center;
  }
}

.vitals {
  padding:30px 20px 30px 20px;
  border: 1px solid var(--grey-light);
}

@media screen and (max-width: 620px) {
  .vitals {
    padding:10px 5px 10px 5px;
  }
}

.big {
  font-size: 2em;
}

.bad {
  border-radius: 20px 20px 20px 20px;
  margin: 0px 0px 20px 20px;
  margin-bottom: 20px;
}


